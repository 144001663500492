<script>
import ECol from "components/layout/components/Col";
import ERow from "components/layout/components/Row";
import {ErpSelect, ErpSField, WindowContent} from "@/plugins/uloc-erp";
import {download, listRelatoriosDinamicos} from "@/domain/relatorios/services";
import {donwloadFile} from "@/utils/downloadFile";
import DateInput from "@/reuse/input/Date";
import RelHeader from "components/relatorios/layout/RelHeader";
import DatetimeModernInput from "@/reuse/input/DatetimeModern"
import {datePtToEn} from "@/utils/date";
import {date} from "uloc-vue";

export default {
  name: 'Relatorios',
  inject: ['page', 'erplayout'],
  mixins: [],
  components: {
    ECol,
    ERow,
    ErpSelect,
    ErpSField,
    DatetimeModernInput,
    RelHeader,
  },
  data() {
    return {
      avancado: false,
      configs: [],
      config: {},
      loadingRD: false,
      gerandoRelatorio: false,
      relatorioDinamico: null,
      relatorios: [],
      filters: {
        data1: null,
        data2: null
      }
    }
  },
  mounted() {
    this.relatoriosDinamicos()
  },
  computed: {},
  methods: {
    relatoriosDinamicos() {
      this.loadingRD = true
      listRelatoriosDinamicos('global')
          .then(({data}) => {
            this.loadingRD = false
            this.relatorios = data.result.slice().map(r => {
              return {
                label: r.nome,
                value: r.id
              }
            })
            console.log(data)
          })
          .catch(error => {
            this.loadingRD = false
            this.alertApiError(error)
          })
    },
    gerarRelatorioDinamico() {
      if (!this.relatorioDinamico) {
        alert('Selecione um relatório para gerar')
        return
      }
      console.log('Gerando ' + this.relatorioDinamico)
      const data1 = datePtToEn(this.filters.data1)
      const data2 = datePtToEn(this.filters.data2)
      if (!date.isValid(data1) || !date.isValid(data2)) {
        alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
        return
      }
      this.gerandoRelatorio = true
      download(this.relatorioDinamico, {
        data1: data1 + ' 00:00:00',
        data2: data2 + ' 23:59:59'
      })
          .then(response => {
            this.gerandoRelatorio = false
            donwloadFile(response)
          })
          .catch(error => {
            this.gerandoRelatorio = false
            this.alertApiError(error)
          })
    }
  },
  meta: {
    title: 'Relatórios',
    name: 'Relatorios'
  }
}
</script>

<template>
  <div>
    <div class="sl-alert-warning">
      <p><i class="fa fa-exclamation-triangle"></i> Seja bem vindo ao novo módulo de relatórios da Suporte Leilões</p>
      Os relatórios antigos continuam sendo visualizados da forma antiga, estaremos atualizando-os aos poucos. Selecione o relatório no menu ao lado.
    </div>
    <div class="m-t">
      <rel-header>
        <form>
        <div class="wrapper-sm">
          <e-row mr>
            <e-col>
              <erp-s-field
                  view="tl"
                  label="Relatórios gerenciados dinamicamente:"
              >
                <erp-select placeholder="Selecione"
                            size="2"
                            :options="relatorios"
                            v-model="relatorioDinamico"/>
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row class="m-t" mr>
            <e-col>
              <erp-s-field label="Data inicial:">
                <datetime-modern-input date-type="date" date-format="##/##/####" label="Data (De)" v-model="filters.data1"/>
              </erp-s-field>
            </e-col>

            <e-col>
              <erp-s-field label="Data final:">
                <datetime-modern-input date-type="date" date-format="##/##/####" label="Data (Até)" v-model="filters.data2"/>
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row mr>
            <e-col>
              <e-btn :loading="gerandoRelatorio" @click="gerarRelatorioDinamico" label="Gerar" class="m-t-xs"/>
            </e-col>
          </e-row>
        </div>
        </form>
      </rel-header>
    </div>
  </div>
</template>
